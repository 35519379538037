* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-dark: #1c1c1c;
  --primary-mid: #828282;
  --primary-light: #fff5ee;
  --primary-accent: #f8971c;
  --secondary-accent: #ffb454;
}

body {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

h2 {
  font-size: 1.75rem;
  font-weight: 450;
}

h3 {
  font-size: 1.35rem;
  font-weight: 400;
  color: var(--primary-accent);
}

p {
  font-size: 1.05rem;
}

hr {
  width: 100%;
  margin: 40px 0px;
  border: 1px solid var(--primary-accent);
}

section {
  margin: 70px 0px;
}

.page {
  margin-top: 10vh;
}

.page-info {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 250px;
  max-width: 1400px;
  width: 80%;
}

.page-info p {
  margin: 16px 0px;
  line-height: 1.5;
}

.how-it-works {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0px 50px;
  text-align: center;
}

.how-it-works ul {
  margin: 50px 0px;
  width: 100%;
  padding: 0px 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  row-gap: 2em;
}

.how-it-works p {
  margin: 0 auto;
  max-width: 80%;
}

.dining-components {
  width: 110%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.dining-components div {
  margin-top: 30px;
  width: 30%;
}

.menu img {
  margin: 50px 0px;
  border: 2px solid var(--primary-accent);
  padding: 10px;
}

.pricing div {
  width: 100%;
  text-align: start;
  margin-bottom: 25px;
}

.pricing h3 {
  font-weight: 500;
}

.about-chef-chris {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 30px;
  line-height: 25px;
}

.meal-prep-menu {
  width: 850px;
}

.submit-button {
  border: none;
  border-radius: 2px;
  background-color: var(--primary-accent);
  padding: 16px 22px;
  height: 52px;
  font-size: 1rem;
  color: var(--primary-light);
}

.submit-button:hover {
  cursor: pointer;
  background-color: var(--secondary-accent);
  color: var(--primary-dark);
}

.submit-button:disabled {
  opacity: 80%;
  background-color: #828282;
  color: var(--primary-light);
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .page-info {
    width: 100vw;
    padding: 0 35px;
  }

  .dining-components {
    flex-direction: column;
    align-items: center;
    text-align: start;
  }

  .dining-components div {
    width: 80%;
  }

  .how-it-works {
    padding: 25px;
  }

  .how-it-works p {
    font-size: 0.9rem;
  }

  .how-it-works ul {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .menu img {
    width: 100%;
    padding: 0;
  }
}
