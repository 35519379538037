nav {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 10vh;
  padding: 5px 80px;
  border-bottom: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
  z-index: 100;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 500px;
  height: 100%;
}

.nav-logo h1 {
  font-size: 1.2rem;
}

.nav-logo img {
  height: 100%;
  width: auto;
  border-radius: 60px;
  border: 3px solid var(--primary-accent);
  padding: 2px;
}

.nav-btn {
  border: none;
  background-color: var(--primary-light);
  cursor: pointer;
  padding: 5px;
}

.nav-btn:hover {
  color: var(--primary-mid);
}

ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

li {
  list-style: none;
  margin: 0 30px;
}

a {
  color: var(--primary-dark);
  padding: 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

a:visited {
  color: var(--primary-dark);
}

a:active {
  color: var(--primary-accent);
}

a:hover {
  color: var(--primary-mid);
}

@media (max-width: 480px) {
  nav {
    padding: 5px 15px;
  }
  .nav-logo h1 {
    display: none;
  }

  li {
    margin: 0 20px;
  }
}
