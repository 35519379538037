.title-section {
  width: 100%;
  height: 100%;
}

.title-section video,
.title-section img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.title-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.3);
}

.title-content {
  margin: auto;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: var(--primary-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 200px;
  gap: 50px;
}

.title-content p {
  font-size: 1.6rem;
  font-weight: 150;
  max-width: 1000px;
}

.title-content h1 {
  max-width: 1000px;
  margin-bottom: 12px;
}

.title-content hr {
  width: 60%;
  min-width: 800px;
  margin: 10px 0px;
}

@media (max-width: 480px) {
  .title-content hr {
    min-width: 90vw;
  }

  .title-content {
    padding: 25px;
  }
}
