.chef-chris {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.chef-chris h2 {
  margin-bottom: 25px;
}

.chef-chris img {
  width: 35%;
  max-width: 400px;
  height: auto;
  border-radius: 200px;
  border: 4px solid var(--primary-accent);
  padding: 5px;
}

@media (max-width: 480px) {
  .chef-chris {
    flex-direction: column;
    gap: 15px;
  }

  .chef-chris img {
    width: 60%;
  }
}
