.booking-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.booking-form div {
  display: flex;
}

.input-container {
  margin: auto;
  justify-content: center;
  width: 50%;
  gap: 30px;
}

.input-section {
  display: flex;
  width: 40%;
  flex-direction: column;
}

textarea {
  resize: none;
}

.large-text {
  width: 45%;
}

input,
select {
  height: 30px;
  background-color: white;
  color: black;
}

input,
select,
textarea {
  margin-top: 5px;
  border: 2px solid var(--primary-accent);
  border-radius: 5px;
  padding: 3px;
  font-size: 1rem;
}

label,
small {
  margin-left: 3px;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .input-container {
    width: 100%;
  }
}
