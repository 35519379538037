footer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 3px solid var(--primary-accent);
  padding: 50px 150px;
  width: 100%;
  gap: 50px;
  text-align: center;
}

footer p {
  margin: 30px 0px;
  font-size: 1.2rem;
  max-width: 800px;
}

footer img {
  height: auto;
  width: 20%;
  max-width: 200px;
}

.icon-link {
  margin-top: 25px;
  font-size: 3rem;
}

@media (max-width: 480px) {
  footer {
    padding: 50px 25px;
  }

  footer img {
    width: 100%;
  }

  footer h1 {
    font-size: 2rem;
  }

  footer p {
    font-size: 1.1rem;
  }
}
