.nav-button {
  border: none;
  border-radius: 2px;
  background-color: var(--primary-accent);
  padding: 16px 22px;
  height: 52px;
  font-size: 1rem;
  color: var(--primary-light);
}

.nav-button:hover {
  cursor: pointer;
  background-color: var(--secondary-accent);
  color: var(--primary-dark);
}